import React from 'react';
import { Link }  from 'react-router-dom';

const PostLink = ( props ) => {
    return (
        <li>
            <Link to={`/read/${props.id}/${props.slug}`}>
                { props.title }
            </Link>
        </li>
    )
}

export default PostLink;