import React from 'react';
import { Helmet } from 'react-helmet';
import Head from './Header';

const Layout = (props) => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ props.title }</title>
                <link rel="stylesheet" href="https://bootswatch.com/4/flatly/bootstrap.min.css" />
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <Head />
            <br />
            <div className="container">
                {props.children}
            </div>
        </>
    )
}

export default Layout;