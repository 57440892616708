import React from 'react';

class About extends React.Component {
    render() {
        return (
            <>
                <h1>About</h1>
                <p>This Web For Learning Connect to API WP JSON</p>
            </>
        )
    }
}

export default About;