import React from 'react';
import './App.css';
import Layout from './component/Layout';
import AboutPage from './pages/About';
import HomePage from './pages/Home';
import ReadPage from './pages/Read';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

class App extends React.Component {
  render () {
    return (
      <Layout>
        <Router>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/about" component={AboutPage} />
            <Route path="/read/:id/:slug" component={ReadPage} />
            <Route render={NoMatch} />
          </Switch>
        </Router>
    </Layout>
    );
  }
}


const NoMatch = () => (
  <div>
    <h1>404</h1>
    Page Not Found
  </div>
);

export default App;
