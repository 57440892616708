import React, { Component} from 'react';
import AsyncFetch from '../AsyncFetch';
import PostLink from '../component/PostLink';

class Home extends Component {
    state = {
        data: []
    }

    async componentDidMount() {
        const url = 'https://bukamatanews.id/wp-json/wp/v2/posts?per_page=25';
        this.setStateAsync({
            data: await AsyncFetch(url)
        })
    }

    setStateAsync( state ){
        return new Promise( resolve => {
            this.setState(state, resolve)
        } )
    }

    render() {
        const { data } = this.state;
        return (
            <ul>
                { 
                    data.length > 0 ? data.map( item => {
                        const {id, title, slug} = item;
                        return (
                            <PostLink key={ id } id={ id } slug={ slug } title={ title.rendered } />
                        )
                    }) : null
                }
            </ul>
        )
    }
}

export default Home;