import React, { Component} from 'react';
import AsyncFetch from '../AsyncFetch';
// import PostLink from '../component/PostLink';

class Read extends Component {
    constructor(props){
        super(props);
        this.id = props.match.params.id;
    }
    state = {
        data: []
    }

    async componentDidMount() {
        const url = 'https://bukamatanews.id/wp-json/wp/v2/posts/'+this.id;
        this.setStateAsync({
            data: await AsyncFetch(url)
        })
    }

    setStateAsync( state ){
        return new Promise( resolve => {
            this.setState(state, resolve)
        } )
    }

    render() {
        const { data } = this.state;
        if (typeof data.title !== 'undefined') {
            const { title, content } = data;
            return (
                <>
                <h1>{ title.rendered }</h1>
                <div className="content">
                    {<div className="content singlecontent" id="contents" dangerouslySetInnerHTML={{ __html: content.rendered }} />}
                </div>
                </>
            )
        } else {
            return (
                <>
                </>
            )
            
        }
        
        
    }
}

export default Read;